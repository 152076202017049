<template>
  <section>
    <router-view></router-view>
  </section>
</template>
<script>
  export default {
    name: 'configuracionOv',
    setup () {
      return {
      }
    }
  }
</script>
<style scoped>
:global(.swal2-container) {z-index: 1000000 !important}
</style>
